import React from 'react'
import './Service.css'
import HeroArea from '../Home/components/Herro'
export const ServicesDetails = () => {
  return (
    <div>
    <HeroArea title="Services Details"/>
    <section className="services-details">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="services-details-img flex justify-center">
            <img
              src="/img/service/services-details-img.png"
              alt="thumb"
            />
          </div>
        </div>
      </div>
      <div className="row article">
        <div className="col-lg-6 col-md-6">
          <div className="services-details-article-text">
            <span>Crated by Fallow </span>
            <p>20 Jan. 2023</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 ">
          <div className="services-details-article-icon">
            <div className="text">
              <p>Share this Service</p>
            </div>
            <div className="icon">
              <i className="fa-sharp fa-solid fa-link" />
              <i className="fa-brands fa-facebook-f" />
              <i className="fa-brands fa-instagram" />
              <i className="fa-brands fa-twitter" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="website">
            <div className="website-text">
              <h2>Website/ App Design UX/ UI Design </h2>
              <span>
                It is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout. The
                point of using Lorem Ipsum is that it has a the a more-or-less
                normal distribution of letters, as opposed to using '
              </span>
              <p>
                Content here, content here', making it look like readable English.
                Many desktop into a publishing packages and web page editors now
                use Lorem Ipsum as their default to a model text, and a search for
                'lorem ipsum' will uncover many web sites still in their to
                infancy. Various versions have evolved over the years, sometimes
                by accident, sometimes on purpose injected humour and the like
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-olg-12">
          <div className="research">
            <div className="website-text">
              <h2>Do your research ahead of time </h2>
              <p>
                It’s easy to simply gather your group, state your problem, and ask
                for ideas. But is this the most efficient way to generate fresh,
                productive ideas? There are specialised methods for brainstorming
                that take idea generation seriously, and you may want to give some
                a try. They include:
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row research-top ">
        <div className="col-lg-4 col-md-6   ">
          <div className="research-img">
            <img src="/img/service/research.png" alt="thumb" />
          </div>
        </div>
        <div className="col-lg-4 col-md-6  ">
          <div className="research-img">
            <img src="/img/service/research-1.png" alt="thumb" />
          </div>
        </div>
        <div className="col-lg-4 d-md-none d-lg-block  ">
          <div className="research-img">
            <img src="/img/service/research-3.png" alt="thumb" />
          </div>
        </div>
        <div className="col-xxl-12">
          <div className="research-top-text">
            <ul>
              <li>
                Brainwriting: Everyone writes down three ideas and passes their
                ideas to the person on their left (or right), who builds off those
                ideas before passing them on again. This way, ideas can
                cross-pollinate, morph and build on top of each other from
                different perspectives. After all, two heads are better than one.
              </li>
            </ul>
            <ul>
              <li>
                Rapid ideation: Everyone writes down as many ideas as they can in
                a set amount of time before anything is discussed or critiqued.
                This is a fun way to get all the good (and bad) ideas out fast and
                bring a sense of fun urgency to the session.
              </li>
            </ul>
            <ul>
              <li>
                Figure storming: The group picks a well-known figure who is not in
                the room and asks how they would approach the problem. For
                example, “How would Barack Obama (or Harry Potter, or anyone else)
                approach this problem?” - prepare for some funny answers here,
                this method is a good ice-breaker for everyone involved.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row query-img align-items-center">
        <div className="col-lg-6 col-md-6">
          <div className="query">
            <h2>Having any Query? Book an appointment.</h2>
          </div>
        </div>
        <div className="col-lg-6  col-md-6">
          <div className="query-btn">
            <div className="btn-style-1">
              <a href="#">Send Us Message</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}
