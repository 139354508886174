import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AboutAreaContent } from "../../../content/Contents";

export const About = () => {
    const [resumeContent, setResumeContent] = useState(AboutAreaContent);
    return (
        <div>
            <div>
                <section class="resume pt-16 pb-16">
                    <div class="container resume-main">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="resume-taitel">
                                    <h2>Resume</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="resume-head">
                                    <div class="resume-head-left">
                                        <h5>{resumeContent.resumeHead.name}</h5>
                                        <p>{resumeContent.resumeHead.title}</p>
                                    </div>
                                    <div class="resume-head-right">
                                        <div class="left">
                                            <h2>{resumeContent.resumeHead.yearsOfExperience}</h2>
                                        </div>
                                        <div class="right">
                                            <span>Years</span>
                                            <p>Experience</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="resume-head-p">
                                    <p>{resumeContent.resumeHead.experienceText}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 resume-pad">
                                <div class="resume-img">
                                    <img src={resumeContent.resumeImage} alt="thumb" />
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div className="experience">
                                    {resumeContent.experiences.map((experience, index) => (
                                        <div key={index} className="experience-item">
                                            <div className={index === 0 ? "experience-inner" : "experience-inner-two"}>
                                                <div className="experience-text">
                                                    <h5>{experience.title}</h5>
                                                    <div className="experience-img">
                                                        <img src={experience.imageUrl} alt="thumb" style={{ width: `${experience.heightOf}` }} />
                                                    </div>
                                                </div>
                                                <div className="experience-btn">
                                                    <NavLink to="">{experience.date}</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg12">
                                <div class="resume-skill"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <h3 className=" font-bold  text-2xl pt-4 text-black">Skills</h3>
                                <div className="skill-btn">
                                    {resumeContent.skills.map((skill, index) => (
                                        <a key={index} href={skill.link} className={skill.active ? "active-two" : ""}>
                                            {skill.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <h3 className=" font-bold  text-2xl pt-4 text-black">Tools I use every day</h3>
                                <div class="tools-btn flex flex-wrap justify-center items-center ">
                                    {resumeContent.ToolItems.map((item, index) => (
                                        <div key={index} className="px-2 md:w-1/12 w-2/12">
                                            <img src={item.src} alt="item" className="w-full h-auto " />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
