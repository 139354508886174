import { Image } from '@chakra-ui/react';
import React from 'react';
import Slider from 'react-slick';

const Customers = () => {
    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        dots: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const customersData = [
        {
            imageUrl: "/img/ayoubleb.jpg",
            text: "“I like working with Rasel, this os our first job but certainly we will be working again soon, there was some issues but the schedule was tight as well, so in general I'm satisfied. good job”",
            name: "Ayoub",
            role: "Web Designer"
        },
        {
            imageUrl: "/img/gmolaire.jpeg",
            text: "“Quick, clean and excellent work.  ”",
            name: "gmolaire",
            role: "Software Engineer"
        },        
        {
            imageUrl: "/img/Davin.jpeg",
            text: "“Efficient in his work. Was able to covert mockup into an actual working page with minimum changes. Amazing job!  ”",
            name: "Davin Acuram",
            role: "Software Engineer"
        },
        // Add more customer data objects as needed
    ];

    return (
        <section className="customers">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="customers-text">
                            <h2>Take care of more than 100K customers</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="customer-head">
                                    <span>CUSTOMER REVIEWS</span>
                                    <h2>What’s Our Customer Say</h2>
                                </div>
                            </div>
                        </div>
                        <div className="customers-item">
                            <Slider {...settings}>
                                {customersData.map((customer, index) => (
                                    <div key={index} className=' px-4 py-4'>
                                        <div className="customer-head-item" style={{height:'300px'}}>
                                            <div className="icon">
                                                {[1, 2, 3, 4, 5].map((star, i) => (
                                                    <i key={i} className="fa-solid fa-star"></i>
                                                ))}
                                            </div>
                                            <div className="text">
                                                <p>{customer.text}</p>
                                            </div>
                                            <div className="customer-head-inner">
                                                <div className="customer-head-innner-df">
                                                    <div className="customer-head-inner-img">
                                                        <Image src={customer.imageUrl} h="70px" w="70px" alt="Customer-thumb" rounded="50px" />
                                                    </div>
                                                    <div className="customer-head-inner-text">
                                                        <h4>{customer.name}</h4>
                                                        <p>{customer.role}</p>
                                                    </div>
                                                </div>
                                                <div className="customer-head-inner-left">
                                                    <img src="/img/customer/Customer-left.png" alt="Customer-thumb" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Customers;
