import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import './Banner.css'
const Banner = () => {
    const [isOpen, setOpen] = useState(false)
    const [playerId, setPlayerId] = useState('n8yGPyRAWoM')
    const Player =()=>{
        
        return (
            <React.Fragment>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={playerId} onClose={() => setOpen(false)} />
            </React.Fragment>
        )
    }

    // banner Content 
    const BannerContent={
        title:"Rasel Hossain I am  full stack developer ",
        description:"Unlocking Digital Potential: Elevate Your Online Presence with Rasel Hossain, Your Premier Full-Stack Web Developer",
        profilePic:"img/banner.png"
    }
    return (
        <div >
            <Player/>
            <section class="banner">
                <div class="container">
                    <div class="row align-items-center  flex-column-reverse flex-lg-row">
                        <div class="col-lg-6">
                            <div class="banner-text">
                                <h1 className=' d-flex align-items-center'> Hey, I am  
                                    <img src="img/hello.svg" alt="hello" style={{width:'60px'}} />
                                </h1>
                                <h1>{BannerContent.title}</h1>

                                <p>
                                    {Banner.description}
                                </p>
                                <div class="banner-vic">
                                    <img src="/img/victor/banner-vic.png" alt="vic-thumb" />
                                </div>
                                <div class="banner-vic-1">
                                    <img src="/img/victor/banner-vic-1.png" alt="vic-thumb" />
                                </div>
                            </div>
                            <div class="banner-btn">
                                <div class="banner-btn-item">
                                    <div class="banner-btn-left">
                                        <div class="btn-style-1">
                                            <NavLink to="/contact" className="d-flex align-items-center" >Hire Me<span><svg width="14" height="11"
                                                viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 9.5L13 5.5M13 5.5L9 1.5M13 5.5L1 5.5" stroke="white"
                                                    stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                            </span></NavLink>
                                        </div>
                                    </div>
                                    <div class="banner-btn-right">
                                        <div class="video">
                                            <button onClick={()=>setOpen(true)}>
                                                    <i
                                                    class="fa-sharp fa-solid fa-play"></i>
                                            </button>
                                        </div>
                                        <div class="text">
                                            <span>WATCH</span>
                                            <p> Intro Video</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="banner-img">
                                <div class="banner-item">
                                    <div class="banner-pos">
                                        <img src="img/banner.png" alt="banner-thumb" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

};

export default Banner;