import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TabContent } from '../../../content/Contents';

const TabMenu = () => {
  const tabContent = TabContent;
  const [currentFilter, setCurrentFilter] = useState("All");
  const [filteredTabItems, setFilteredTabItems] = useState(tabContent.tabItems.slice(0, 6)); // Initially show only the first 6 items

  const filterItems = (category) => {
    let filteredItems;
    if (category === "All") {
      filteredItems = tabContent.tabItems.slice(0, 6).map(item => ({ ...item, hidden: false })); // Ensure only first 6 items are considered
    } else {
      filteredItems = tabContent.tabItems.map(item => ({
        ...item,
        hidden: !item.group.includes(category)
      }));
    }
    const limitedItems = filteredItems.slice(0,14);
    setFilteredTabItems(limitedItems);
    setCurrentFilter(category);
  };

  return (
    <div>
      <section id="tab">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="tab-head">
                <h2 className='text-black'>{tabContent.tabHeadTitle}</h2>
                <p className='text-black'>{tabContent.tabHeadDetails}</p>
              </div>
            </div>
          </div>
          <div className="tab-pane fade show in active" id="grid">
            <ul className="shuffle-filter course-filter">
              <li onClick={() => filterItems("All")} className={currentFilter === "All" ? "active" : ""}>All</li>
              {tabContent.tabItems.map((item, index) => (
                <li key={index} onClick={() => filterItems(item.category)} className={currentFilter === item.category ? "active" : ""} style={item.category ? { display: 'block' } : { display: 'none' }}>{item.category}</li>
              ))}
            </ul>
            <div className="row shuffle-container shuffle">
              {filteredTabItems.map((item, index) => ( // Map over filteredTabItems directly
                !item.hidden && (
                  <div key={index} className="col-lg-4 col-md-6 shuffle-item shuffle-item filtered" style={{ transitionDelay: `${index * 0.1}s` }}>
                    <div className="tab-item s-mt">
                      <div className="tab-img tab_hover">
                        <img src={item.imgSrc} alt="thumb" />
                        <div className="tab-img-overlay">
                          <div className="over-d-f">
                            <div className="tab-img-overlay-text">
                              <h4>{item.title}</h4>
                              <p>{item.category}</p>
                              <div className="tab-over-btn tab-over-btn-2 flex flex-col justify-center items-center">
                                <NavLink to={`/portfolio/${item.id}`} > View Project</NavLink>
                                <NavLink to={``} className="mt-3">Live Preview</NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="tap-btnn flex items-center justify-center">
                  <div className="btn-style-2 ">
                    <NavLink to={tabContent.seeMoreUrl}>See More</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TabMenu;
