import React from 'react';
import './Contact.css';
import HeroArea from '../Home/components/Herro';
import { Contact } from '../Home/components/Contact';



export const ContactArea = () => {
    const contactInfo = {
        address: {
            street: "Sultanpur,Sahapara",
            city: "Satkhira",
            country: "Bangladesh",
            countryCode: "BD"
        },
        phoneNumber: "+8801955795410",
        emailAddress: "raselwebzone@gmail.com"
    };

    return (
        <div>
            <HeroArea />
            <section className="contact-me">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contuict-me-main">
                                <div className="contact-me-item">
                                    <div className="contact-me-inner flex items-center ">
                                        <div className="icon w-36">
                                            <img src="img/svg/svg3.svg" alt="location" />
                                        </div>
                                        <div className="text">
                                            <h3>Our Address</h3>
                                            <p>
                                                {contactInfo.address.street}, {contactInfo.address.city} <br />
                                                {contactInfo.address.country}, {contactInfo.address.countryCode}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-me-item">
                                    <div className="contact-me-inner flex items-center">
                                        <div className="icon w-36">
                                            <img src="img/svg/svg4.svg" alt="location" />
                                        </div>
                                        <div className="text">
                                            <h3>Contact Info</h3>
                                            <p>Open a chat or give us call at</p>
                                            <h5>{contactInfo.phoneNumber}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Contact />
        </div>

    );
};

export default ContactArea;
