import React from "react";
import Slider from "react-slick";

import { NavLink } from "react-router-dom";
import { ServiceContent } from "../../../content/Contents";
import { Image } from "@chakra-ui/react";


const Services = () => {
    const content =ServiceContent
    return (
        <div>
            <section class="service">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5">
                            <div class="service-head">
                                <h2>{content.ServiceData.title}</h2>
                                <div class="service-vic">
                                    <img src="/img/victor/service.png" alt="service-vic" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                        <div class="col-lg-6  ">
                            <div class="service-text  ">
                                <p>{content.ServiceData.details}</p>
                            </div>
                        </div>
                    </div>
                    <div class=" mar-top">
                        <Slider {...content.SlideSettings}>
                            {content.slideContent.map((items, id) => {
                                return (
                                    <div class="col-lg-4  m-r py-5" key={id}>
                                        <div class="service-item">
                                            <div class="service-overelay"></div>
                                            <div class="service-icon">
                                                <span> <Image src={items.icon} alt="Logo" w="100px" /></span>
                                            </div>
                                            <div class="service-item-text">
                                                <h3>{items.title}</h3>
                                                <p>{items.details}</p>
                                            </div>
                                            <div class="service-btn">
                                                <NavLink to={items.url}>
                                                    Learn more{" "}
                                                    <span>
                                                        {" "}
                                                        <i class="fa-solid fa-arrow-right"></i>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Services
