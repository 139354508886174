import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from '@chakra-ui/react'
import MobileMenu from './Layouts/MobileMenu'
import Footer from './Layouts/Footer'
import Routing from './Routes/Routes'
import './App.css'
import { Header } from './Layouts/Header'
import { MobileHeaderContent, footerContent } from './content/Contents'
import { BackToTop } from './components/BackToTop/BackToTop'
const { Button } = chakraTheme.components

const theme = extendBaseTheme({
  components: {
    Button,
  },
})

function App() {
  return (
    <ChakraBaseProvider theme={theme}>
          <Header/>
            <MobileMenu content={MobileHeaderContent}/>
            <Routing/>
          <Footer content={footerContent} />
          <BackToTop/>
    </ChakraBaseProvider>
  )
}

export default App