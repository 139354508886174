import React from 'react';

export const Skills = ({ title, description, buttonText }) => {
  const handleDownload = () => {
    // Replace 'path_to_your_file' with the actual path of the file you want to download
    const fileUrl = '/img/cv/RaselHossainCV.pdf';

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'Rasel Hossain CV.pdf'; // Set the desired file name

    // Trigger the download
    anchor.click();

    // Clean up
    URL.revokeObjectURL(fileUrl);
  };
  return (
    <div>
      <section className="skills skills-1 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="skill-text">
                <h2>{title}</h2>
              </div>
            </div>
            <div className="col-lg-2" />
            <div className="col-lg-5">
              <div className="skill-right">
                <p>{description}</p>
              </div>
              <div className="skill-rigght-btn">
                <div className="btn-style-1">
                  <button type='button' onClick={handleDownload}>
                    {buttonText}
                    <span>
                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* SVG path here */}
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
