import React from 'react';
import Banner from './components/Banner';
import Portfolio from './components/Portfolio';
import Services from './components/Service';
import Customers from './components/Customers';
import TabMenu from './components/TabMenu';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Blog } from './components/Blog';

const Home = () => {
    return (
        <div>
            <Banner/>
            <Portfolio/>
            <About/>
            <Services/>
            <TabMenu />
            <Customers/>
            <Contact/>
            <Blog/>
        </div>
    );
};

export default Home;