import React from 'react'
import { NavLink } from 'react-router-dom'
import './Product.css'
export const Product = () => {
  return (
    <div>
    <section className="product">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="product-head">
            <h2>Browse this week's Best selling</h2>
            <div className="product-vic">
              <img
                src="./assets/image/others/victor/product-2-vic.png"
                alt="thumb"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="product-box">
            <div className="product-sarch">
              <div className="product-btn">
                <div className="product-btn-left">
                  <input
                    type="text"
                    className="p-b-text"
                    placeholder="Search Properties... "
                  />
                </div>
                <div className="product-btn-right">
                  <NavLink to="#">Subscribe</NavLink>
                </div>
              </div>
              <div className="results">
                <p>
                  Showing <span>1-2</span> of <span> 17</span> results
                </p>
              </div>
            </div>
            <div className="product-grid">
              <div className="icon-1 flex items-center justify-center">
                <span>
                  <svg
                    width={20}
                    height={19}
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.46209 0H2.29369C1.0291 0 0 1.0291 0 2.29369V6.46158C0 7.72617 1.0291 8.75528 2.29369 8.75528H6.46158C7.72617 8.75528 8.75528 7.72617 8.75528 6.46158V2.29369C8.75579 1.0291 7.72668 0 6.46209 0ZM7.22666 6.46209C7.22666 6.88362 6.88362 7.22666 6.46209 7.22666H2.29369C1.87216 7.22666 1.52913 6.88362 1.52913 6.46209V2.2942C1.52913 1.87267 1.87216 1.52964 2.29369 1.52964H6.46158C6.88311 1.52964 7.22615 1.87267 7.22615 2.2942L7.22666 6.46209ZM16.7745 0H12.6061C11.3415 0 10.3124 1.0291 10.3124 2.29369V6.46158C10.3124 7.72617 11.3415 8.75528 12.6061 8.75528H16.7745C18.0391 8.75528 19.0682 7.72617 19.0682 6.46158V2.29369C19.0682 1.0291 18.0396 0 16.7745 0ZM17.5391 6.46209C17.5391 6.88362 17.1961 7.22666 16.7745 7.22666H12.6061C12.1846 7.22666 11.8416 6.88362 11.8416 6.46209V2.2942C11.8416 1.87267 12.1846 1.52964 12.6061 1.52964H16.7745C17.1961 1.52964 17.5391 1.87267 17.5391 2.2942V6.46209ZM6.46209 9.82159H2.29369C1.0291 9.82159 0 10.8507 0 12.1153V16.2832C0 17.5478 1.0291 18.5769 2.29369 18.5769H6.46158C7.72617 18.5769 8.75528 17.5478 8.75528 16.2832V12.1153C8.75579 10.8502 7.72668 9.82159 6.46209 9.82159ZM7.22666 16.2832C7.22666 16.7047 6.88362 17.0477 6.46209 17.0477H2.29369C1.87216 17.0477 1.52913 16.7047 1.52913 16.2832V12.1153C1.52913 11.6938 1.87216 11.3507 2.29369 11.3507H6.46158C6.88311 11.3507 7.22615 11.6938 7.22615 12.1153L7.22666 16.2832ZM16.7745 9.82159H12.6061C11.3415 9.82159 10.3124 10.8507 10.3124 12.1153V16.2832C10.3124 17.5478 11.3415 18.5769 12.6061 18.5769H15.7205C16.1425 18.5769 16.485 18.2343 16.485 17.8123C16.485 17.3903 16.1425 17.0477 15.7205 17.0477H12.6061C12.1846 17.0477 11.8416 16.7047 11.8416 16.2832V12.1153C11.8416 11.6938 12.1846 11.3507 12.6061 11.3507H16.7745C17.1961 11.3507 17.5391 11.6938 17.5391 12.1153V15.6088C17.5391 16.0309 17.8816 16.3734 18.3037 16.3734C18.7257 16.3734 19.0682 16.0309 19.0682 15.6088V12.1153C19.0682 10.8502 18.0396 9.82159 16.7745 9.82159Z" />
                  </svg>
                </span>
              </div>
              <div className="icon flex items-center justify-center">
                <span>
                  <svg
                    width={27}
                    height={19}
                    viewBox="0 0 27 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.133 0H6.61649C5.70432 0 4.96484 0.739475 4.96484 1.65165C4.96484 2.56383 5.70432 3.3033 6.61649 3.3033H23.133C24.0452 3.3033 24.7847 2.56383 24.7847 1.65165C24.7847 0.739475 24.0452 0 23.133 0Z" />
                    <path d="M3.31256 1.65251C3.3107 1.21518 3.13547 0.796437 2.82532 0.488095C2.18112 -0.152282 1.14068 -0.152282 0.496489 0.488095C0.186288 0.796437 0.0110583 1.21518 0.00925183 1.65251C-0.00308394 1.7595 -0.00308394 1.86758 0.00925183 1.97458C0.0279361 2.0822 0.0583885 2.18744 0.100093 2.28839C0.144429 2.3862 0.196921 2.48014 0.257 2.56918C0.316304 2.66177 0.385519 2.74761 0.463456 2.82518C0.538606 2.90002 0.621653 2.9665 0.711204 3.02338C0.798173 3.08671 0.892472 3.13941 0.991984 3.18029C1.10146 3.23706 1.21816 3.27877 1.33883 3.30416C1.44583 3.31613 1.55391 3.31613 1.6609 3.30416C2.09704 3.30452 2.51563 3.13234 2.82532 2.82518C2.90325 2.74761 2.97247 2.66177 3.03177 2.56918C3.09185 2.48014 3.14434 2.3862 3.18868 2.28839C3.24138 2.18868 3.28293 2.08343 3.31256 1.97458C3.32489 1.86758 3.32489 1.7595 3.31256 1.65251Z" />
                    <path d="M3.31112 9.08415C3.3234 8.97715 3.3234 8.86907 3.31112 8.76208C3.28257 8.65549 3.24097 8.55289 3.18724 8.45652C3.14471 8.3552 3.09212 8.25838 3.03033 8.16748C2.97309 8.07592 2.90362 7.99256 2.82388 7.91974C2.17968 7.27936 1.13925 7.27936 0.49505 7.91974C0.184849 8.22808 0.00961899 8.64682 0.0078125 9.08415C0.0110126 9.30181 0.0529748 9.51709 0.131686 9.72004C0.173081 9.81774 0.222837 9.91168 0.280335 10.0008C0.343149 10.0906 0.415099 10.1736 0.49505 10.2486C0.568032 10.3282 0.651337 10.3976 0.742797 10.455C0.829767 10.5184 0.924015 10.5711 1.02358 10.6119C1.12428 10.6543 1.22962 10.6848 1.33739 10.7028C1.44305 10.7265 1.55118 10.7376 1.65946 10.7358C1.76646 10.7481 1.87454 10.7481 1.98154 10.7358C2.08662 10.7178 2.18923 10.6873 2.28709 10.645C2.38934 10.6044 2.48637 10.5517 2.57613 10.4881C2.66759 10.4307 2.7509 10.3612 2.82388 10.2816C2.90347 10.2086 2.97294 10.1253 3.03033 10.0338C3.09382 9.94693 3.14652 9.85268 3.18724 9.75307C3.24366 9.64344 3.28531 9.52679 3.31112 9.40622C3.32381 9.29922 3.32381 9.19115 3.31112 9.08415Z" />
                    <path d="M3.31122 16.5158C3.32345 16.4088 3.32345 16.3007 3.31122 16.1937C3.28267 16.0845 3.24107 15.9792 3.18734 15.8799C3.14301 15.7821 3.09051 15.6882 3.03044 15.5991C2.97304 15.5077 2.90357 15.4244 2.82398 15.3514C2.17978 14.711 1.13935 14.711 0.495151 15.3514C0.415562 15.4244 0.346089 15.5077 0.288694 15.5991C0.228616 15.6882 0.176124 15.7821 0.131788 15.8799C0.0889479 15.9804 0.058444 16.0858 0.0409468 16.1937C0.0176688 16.2995 0.00662339 16.4075 0.00791375 16.5158C0.00977185 16.9531 0.185002 17.3719 0.495151 17.6802C0.568133 17.7598 0.651438 17.8293 0.742899 17.8867C0.829868 17.95 0.924116 18.0027 1.02368 18.0436C1.12438 18.0859 1.22972 18.1164 1.33749 18.1344C1.44315 18.1581 1.55128 18.1692 1.65957 18.1674C1.76656 18.1798 1.87464 18.1798 1.98164 18.1674C2.08672 18.1494 2.18933 18.1189 2.28719 18.0766C2.38944 18.036 2.48647 17.9833 2.57623 17.9197C2.66769 17.8623 2.751 17.7928 2.82398 17.7132C2.90357 17.6403 2.97304 17.5569 3.03044 17.4655C3.09397 17.3786 3.14667 17.2843 3.18734 17.1847C3.24371 17.075 3.28536 16.9584 3.31122 16.8379C3.32391 16.7309 3.32391 16.6228 3.31122 16.5158Z" />
                    <path d="M24.7847 7.43164H6.61649C5.70432 7.43164 4.96484 8.17112 4.96484 9.08329C4.96484 9.99547 5.70432 10.7349 6.61649 10.7349H24.7847C25.6968 10.7349 26.4363 9.99547 26.4363 9.08329C26.4363 8.17112 25.6968 7.43164 24.7847 7.43164Z" />
                    <path d="M17.3522 14.8652H6.6165C5.70432 14.8652 4.96484 15.6047 4.96484 16.5169C4.96484 17.4291 5.70432 18.1685 6.6165 18.1685H17.3522C18.2644 18.1685 19.0039 17.4291 19.0039 16.5169C19.0039 15.6047 18.2644 14.8652 17.3522 14.8652Z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row featured-top">
        <div className="col-lg-6 col-md-6 padd">
          <div className="featured-item">
            <div className="featured-item-img">
              <img src="./assets/image/others/featured-1.png" alt="thumb" />
              <div className="featured-overlay">
                <div className="featured-overlay-inner">
                  <div className="featured-overlay-text">
                    <h3>
                      <NavLink to="#">
                        Master in Design System in Figam &amp; Sketch
                      </NavLink>
                    </h3>
                  </div>
                  <div className="featured-overlay-icon">
                    <div className="icon">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                    <div className="text">
                      <span>(310)</span>
                    </div>
                  </div>
                  <div className="featured-overlay-btm-text">
                    <span>$1700.0</span>
                    <span>
                      <del>$2900.0</del>
                    </span>
                  </div>
                  <div className="featured-overlay-btm-img">
                    <span>
                      <svg
                        width={26}
                        height={35}
                        viewBox="0 0 26 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4.37507C0 3.21473 0.456546 2.10191 1.2692 1.28143C2.08186 0.460944 3.18406 0 4.33333 0L21.6667 0C22.8159 0 23.9181 0.460944 24.7308 1.28143C25.5435 2.10191 26 3.21473 26 4.37507V33.9068C25.9999 34.1046 25.9466 34.2988 25.8459 34.4685C25.7452 34.6382 25.6008 34.7771 25.428 34.8705C25.2552 34.9639 25.0606 35.0082 24.8649 34.9987C24.6692 34.9893 24.4796 34.9264 24.3165 34.8168L13 28.6589L1.6835 34.8168C1.52036 34.9264 1.33083 34.9893 1.1351 34.9987C0.939364 35.0082 0.744755 34.9639 0.571999 34.8705C0.399243 34.7771 0.254809 34.6382 0.15408 34.4685C0.0533521 34.2988 0.000101228 34.1046 0 33.9068V4.37507ZM4.33333 2.18754C3.7587 2.18754 3.2076 2.41801 2.80127 2.82825C2.39494 3.23849 2.16667 3.7949 2.16667 4.37507V31.8636L12.3998 26.4342C12.5776 26.3147 12.7864 26.251 13 26.251C13.2136 26.251 13.4224 26.3147 13.6002 26.4342L23.8333 31.8636V4.37507C23.8333 3.7949 23.6051 3.23849 23.1987 2.82825C22.7924 2.41801 22.2413 2.18754 21.6667 2.18754H4.33333Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 padd ">
          <div className="featured-item">
            <div className="featured-item-img">
              <img src="./assets/image/others/featured-2.png" alt="thumb" />
              <div className="featured-overlay">
                <div className="featured-overlay-inner">
                  <div className="featured-overlay-text">
                    <h3>
                      <NavLink href="#">3D Design &amp; Implement on the web.</NavLink>
                    </h3>
                  </div>
                  <div className="featured-overlay-icon">
                    <div className="icon">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                    <div className="text">
                      <span>(310)</span>
                    </div>
                  </div>
                  <div className="featured-overlay-btm-text">
                    <span>$2500.0</span>
                    <span>
                      <del>$750.0</del>
                    </span>
                  </div>
                  <div className="featured-overlay-btm-img">
                    <span>
                      <svg
                        width={26}
                        height={35}
                        viewBox="0 0 26 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4.37507C0 3.21473 0.456546 2.10191 1.2692 1.28143C2.08186 0.460944 3.18406 0 4.33333 0L21.6667 0C22.8159 0 23.9181 0.460944 24.7308 1.28143C25.5435 2.10191 26 3.21473 26 4.37507V33.9068C25.9999 34.1046 25.9466 34.2988 25.8459 34.4685C25.7452 34.6382 25.6008 34.7771 25.428 34.8705C25.2552 34.9639 25.0606 35.0082 24.8649 34.9987C24.6692 34.9893 24.4796 34.9264 24.3165 34.8168L13 28.6589L1.6835 34.8168C1.52036 34.9264 1.33083 34.9893 1.1351 34.9987C0.939364 35.0082 0.744755 34.9639 0.571999 34.8705C0.399243 34.7771 0.254809 34.6382 0.15408 34.4685C0.0533521 34.2988 0.000101228 34.1046 0 33.9068V4.37507ZM4.33333 2.18754C3.7587 2.18754 3.2076 2.41801 2.80127 2.82825C2.39494 3.23849 2.16667 3.7949 2.16667 4.37507V31.8636L12.3998 26.4342C12.5776 26.3147 12.7864 26.251 13 26.251C13.2136 26.251 13.4224 26.3147 13.6002 26.4342L23.8333 31.8636V4.37507C23.8333 3.7949 23.6051 3.23849 23.1987 2.82825C22.7924 2.41801 22.2413 2.18754 21.6667 2.18754H4.33333Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6  col-md-6 product-mt padd ">
          <div className="featured-item">
            <div className="featured-item-img">
              <img src="./assets/image/others/product-01.png" alt="thumb" />
              <div className="featured-overlay">
                <div className="featured-overlay-inner">
                  <div className="featured-overlay-text">
                    <h3>
                      <NavLink href="#">3D Design &amp; Implement on the web.</NavLink>
                    </h3>
                  </div>
                  <div className="featured-overlay-icon">
                    <div className="icon">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                    <div className="text">
                      <span>(310)</span>
                    </div>
                  </div>
                  <div className="featured-overlay-btm-text">
                    <span>$2500.0</span>
                    <span>
                      <del>$750.0</del>
                    </span>
                  </div>
                  <div className="featured-overlay-btm-img">
                    <span>
                      <svg
                        width={26}
                        height={35}
                        viewBox="0 0 26 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4.37507C0 3.21473 0.456546 2.10191 1.2692 1.28143C2.08186 0.460944 3.18406 0 4.33333 0L21.6667 0C22.8159 0 23.9181 0.460944 24.7308 1.28143C25.5435 2.10191 26 3.21473 26 4.37507V33.9068C25.9999 34.1046 25.9466 34.2988 25.8459 34.4685C25.7452 34.6382 25.6008 34.7771 25.428 34.8705C25.2552 34.9639 25.0606 35.0082 24.8649 34.9987C24.6692 34.9893 24.4796 34.9264 24.3165 34.8168L13 28.6589L1.6835 34.8168C1.52036 34.9264 1.33083 34.9893 1.1351 34.9987C0.939364 35.0082 0.744755 34.9639 0.571999 34.8705C0.399243 34.7771 0.254809 34.6382 0.15408 34.4685C0.0533521 34.2988 0.000101228 34.1046 0 33.9068V4.37507ZM4.33333 2.18754C3.7587 2.18754 3.2076 2.41801 2.80127 2.82825C2.39494 3.23849 2.16667 3.7949 2.16667 4.37507V31.8636L12.3998 26.4342C12.5776 26.3147 12.7864 26.251 13 26.251C13.2136 26.251 13.4224 26.3147 13.6002 26.4342L23.8333 31.8636V4.37507C23.8333 3.7949 23.6051 3.23849 23.1987 2.82825C22.7924 2.41801 22.2413 2.18754 21.6667 2.18754H4.33333Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6  product-mt padd ">
          <div className="featured-item">
            <div className="featured-item-img">
              <img src="./assets/image/others/product-02.png" alt="thumb" />
              <div className="featured-overlay">
                <div className="featured-overlay-inner">
                  <div className="featured-overlay-text">
                    <h3>
                      <NavLink href="#">3D Design &amp; Implement on the web.</NavLink>
                    </h3>
                  </div>
                  <div className="featured-overlay-icon">
                    <div className="icon">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                    <div className="text">
                      <span>(310)</span>
                    </div>
                  </div>
                  <div className="featured-overlay-btm-text">
                    <span>$2500.0</span>
                    <span>
                      <del>$750.0</del>
                    </span>
                  </div>
                  <div className="featured-overlay-btm-img">
                    <span>
                      <svg
                        width={26}
                        height={35}
                        viewBox="0 0 26 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4.37507C0 3.21473 0.456546 2.10191 1.2692 1.28143C2.08186 0.460944 3.18406 0 4.33333 0L21.6667 0C22.8159 0 23.9181 0.460944 24.7308 1.28143C25.5435 2.10191 26 3.21473 26 4.37507V33.9068C25.9999 34.1046 25.9466 34.2988 25.8459 34.4685C25.7452 34.6382 25.6008 34.7771 25.428 34.8705C25.2552 34.9639 25.0606 35.0082 24.8649 34.9987C24.6692 34.9893 24.4796 34.9264 24.3165 34.8168L13 28.6589L1.6835 34.8168C1.52036 34.9264 1.33083 34.9893 1.1351 34.9987C0.939364 35.0082 0.744755 34.9639 0.571999 34.8705C0.399243 34.7771 0.254809 34.6382 0.15408 34.4685C0.0533521 34.2988 0.000101228 34.1046 0 33.9068V4.37507ZM4.33333 2.18754C3.7587 2.18754 3.2076 2.41801 2.80127 2.82825C2.39494 3.23849 2.16667 3.7949 2.16667 4.37507V31.8636L12.3998 26.4342C12.5776 26.3147 12.7864 26.251 13 26.251C13.2136 26.251 13.4224 26.3147 13.6002 26.4342L23.8333 31.8636V4.37507C23.8333 3.7949 23.6051 3.23849 23.1987 2.82825C22.7924 2.41801 22.2413 2.18754 21.6667 2.18754H4.33333Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6  col-md-6 product-mt padd ">
          <div className="featured-item">
            <div className="featured-item-img">
              <img src="./assets/image/others/product-03.png" alt="thumb" />
              <div className="featured-overlay">
                <div className="featured-overlay-inner">
                  <div className="featured-overlay-text">
                    <h3>
                      <NavLink href="#">3D Design &amp; Implement on the web.</NavLink>
                    </h3>
                  </div>
                  <div className="featured-overlay-icon">
                    <div className="icon">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                    <div className="text">
                      <span>(310)</span>
                    </div>
                  </div>
                  <div className="featured-overlay-btm-text">
                    <span>$2500.0</span>
                    <span>
                      <del>$750.0</del>
                    </span>
                  </div>
                  <div className="featured-overlay-btm-img">
                    <span>
                      <svg
                        width={26}
                        height={35}
                        viewBox="0 0 26 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4.37507C0 3.21473 0.456546 2.10191 1.2692 1.28143C2.08186 0.460944 3.18406 0 4.33333 0L21.6667 0C22.8159 0 23.9181 0.460944 24.7308 1.28143C25.5435 2.10191 26 3.21473 26 4.37507V33.9068C25.9999 34.1046 25.9466 34.2988 25.8459 34.4685C25.7452 34.6382 25.6008 34.7771 25.428 34.8705C25.2552 34.9639 25.0606 35.0082 24.8649 34.9987C24.6692 34.9893 24.4796 34.9264 24.3165 34.8168L13 28.6589L1.6835 34.8168C1.52036 34.9264 1.33083 34.9893 1.1351 34.9987C0.939364 35.0082 0.744755 34.9639 0.571999 34.8705C0.399243 34.7771 0.254809 34.6382 0.15408 34.4685C0.0533521 34.2988 0.000101228 34.1046 0 33.9068V4.37507ZM4.33333 2.18754C3.7587 2.18754 3.2076 2.41801 2.80127 2.82825C2.39494 3.23849 2.16667 3.7949 2.16667 4.37507V31.8636L12.3998 26.4342C12.5776 26.3147 12.7864 26.251 13 26.251C13.2136 26.251 13.4224 26.3147 13.6002 26.4342L23.8333 31.8636V4.37507C23.8333 3.7949 23.6051 3.23849 23.1987 2.82825C22.7924 2.41801 22.2413 2.18754 21.6667 2.18754H4.33333Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 product-mt  padd">
          <div className="featured-item">
            <div className="featured-item-img">
              <img src="./assets/image/others/product-04.png" alt="thumb" />
              <div className="featured-overlay">
                <div className="featured-overlay-inner">
                  <div className="featured-overlay-text">
                    <h3>
                      <NavLink href="#">3D Design &amp; Implement on the web.</NavLink>
                    </h3>
                  </div>
                  <div className="featured-overlay-icon">
                    <div className="icon">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                    <div className="text">
                      <span>(310)</span>
                    </div>
                  </div>
                  <div className="featured-overlay-btm-text">
                    <span>$2500.0</span>
                    <span>
                      <del>$750.0</del>
                    </span>
                  </div>
                  <div className="featured-overlay-btm-img">
                    <span>
                      <svg
                        width={26}
                        height={35}
                        viewBox="0 0 26 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4.37507C0 3.21473 0.456546 2.10191 1.2692 1.28143C2.08186 0.460944 3.18406 0 4.33333 0L21.6667 0C22.8159 0 23.9181 0.460944 24.7308 1.28143C25.5435 2.10191 26 3.21473 26 4.37507V33.9068C25.9999 34.1046 25.9466 34.2988 25.8459 34.4685C25.7452 34.6382 25.6008 34.7771 25.428 34.8705C25.2552 34.9639 25.0606 35.0082 24.8649 34.9987C24.6692 34.9893 24.4796 34.9264 24.3165 34.8168L13 28.6589L1.6835 34.8168C1.52036 34.9264 1.33083 34.9893 1.1351 34.9987C0.939364 35.0082 0.744755 34.9639 0.571999 34.8705C0.399243 34.7771 0.254809 34.6382 0.15408 34.4685C0.0533521 34.2988 0.000101228 34.1046 0 33.9068V4.37507ZM4.33333 2.18754C3.7587 2.18754 3.2076 2.41801 2.80127 2.82825C2.39494 3.23849 2.16667 3.7949 2.16667 4.37507V31.8636L12.3998 26.4342C12.5776 26.3147 12.7864 26.251 13 26.251C13.2136 26.251 13.4224 26.3147 13.6002 26.4342L23.8333 31.8636V4.37507C23.8333 3.7949 23.6051 3.23849 23.1987 2.82825C22.7924 2.41801 22.2413 2.18754 21.6667 2.18754H4.33333Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-12">
          <div className="h-1-blog-btn">
            <nav aria-label="...">
              <ul className="pagination">
                <li className="page-item disabled">
                  <span className="page-link">
                    <i className="fa-solid fa-angle-left" />
                  </span>
                </li>
                <li className="page-item active">
                  <NavLink className="page-link" to="#">
                    1
                  </NavLink>
                </li>
                <li className="page-item " aria-current="page">
                  <span className="page-link">2</span>
                </li>
                <li className="page-item">
                  <NavLink className="page-link" to="#">
                    ...
                  </NavLink>
                </li>
                <li className="page-item">
                  <NavLink className="page-link" to="#">
                    3
                  </NavLink>
                </li>
                <li className="page-item">
                  <NavLink className="page-link" to="#">
                    <i className="fa-solid fa-angle-right" />
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}
