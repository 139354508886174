import React, { useState } from 'react';

const MobileMenu = ({ content }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <header className="mobile-header">
                <div className="container-full">
                    <div className="mobile-header__container">
                        <div className="p-left">
                            <div className="logo">
                                <a href={content.logo.link}>
                                    <img src={content.logo.image} alt="logo-thumb" style={{ width: '150px' }} />
                                </a>
                            </div>
                        </div>
                        <div className="p-right">
                            <button id="nav-opn-btn" onClick={toggleMenu}>
                                <i className="fa-solid fa-bars"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <aside id="offCanvas-nav" class={ menuOpen ? 'open' : '' }>
                <nav className="m-nav">
                    <button id="nav-cls-btn" onClick={toggleMenu}><i className="fa-solid fa-xmark"></i></button>
                    <div className="logo">
                        <a href={content.logo.link}>
                            <img src={content.logo.image} alt="logo-thumb" style={{width:'150px'}} />
                        </a>
                    </div>
                    <ul className="nav-links">
                        {content.navLinks.map((link, index) => (
                            <li key={index} className="dropdown">
                                <a href={link.url}>{link.title}</a>
                                {link.subLinks && link.subLinks.length > 0 && (
                                    <ul className="d-menu">
                                        {link.subLinks.map((subLink, subIndex) => (
                                            <li key={subIndex}><a href={subLink.url}>{subLink.title}</a></li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                    <ul className="social-icons">
                        {content.socialIcons.map((icon, index) => (
                            <li key={index}>
                                <a href={icon.url}><i className={icon.class}></i></a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </aside>
        </div>
    );
};

export default MobileMenu;

