import React from 'react'
import HeroArea from '../Home/components/Herro'
import { ProductShowcase } from './PortfoiloShowcase'
import { Skills } from '../Home/components/Skills'

export const Portfolio = () => {
  return (
        <div>
            <HeroArea/>
            <ProductShowcase/>
            <Skills         title="Hire me as a Product Designer"
        description="I am a product designer excited about unique ideas and helping clients achieve their goals."
        buttonText="Download CV"
        buttonLink="#"/>
        </div>
  )
}
