import React from "react";
import HeroArea from "../Home/components/Herro";

const Terms = () => {
  return (
    <div>
      <HeroArea />
      <section className="services-details">
        <div className="container">
          <div className="row article">
            <div className="col-lg-12">
              <div className="blog-details-article-head">
                <h1 className=" text-2xl font-bold">Terms and Conditions</h1>
                <p className="py-3">Welcome to Rasel Web Zone!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="website">
                <div className="website-text">
                  <span>
                    These terms and conditions outline the rules and regulations
                    for the use of Rasel Web Zone's Website, located at
                    www.raselwebzone.com. By accessing this website, we assume
                    you accept these terms and conditions. Do not continue to
                    use Rasel Web Zone if you do not agree to all the terms and
                    conditions stated on this page.
                  </span>
                  <h2 className=" text-2xl font-bold pt-5">Cookies:</h2>
                  <p>
                    The website uses cookies to help personalize your online
                    experience. By accessing Rasel Web Zone, you agreed to use
                    the required cookies. A cookie is a text file that is placed
                    on your hard disk by a web page server. Cookies cannot be
                    used to run programs or deliver viruses to your computer.
                    Cookies are uniquely assigned to you and can only be read by
                    a web server in the domain that issued the cookie to you. We
                    may use cookies to collect, store, and track information for
                    statistical or marketing purposes to operate our website.
                    You have the ability to accept or decline optional Cookies.
                    There are some required Cookies that are necessary for the
                    operation of our website. These cookies do not require your
                    consent as they always work. Please keep in mind that by
                    accepting required Cookies, you also accept third-party
                    Cookies, which might be used via third-party services if you
                    use such services on our website. For example, a video
                    display window provided by third parties and integrated into
                    our website.
                  </p>
                  <h2 className=" text-2xl font-bold pt-5">License</h2>
                  <p>
                    Unless otherwise stated, Rasel Web Zone and/or its licensors
                    own the intellectual property rights for all material on
                    Rasel Web Zone. All intellectual property rights are
                    reserved. You may access this from Rasel Web Zone for your
                    personal use subjected to restrictions set in these terms
                    and conditions. You must not: Republish material from Rasel
                    Web Zone Sell, rent, or sub-license material from Rasel Web
                    Zone Reproduce, duplicate or copy material from Rasel Web
                    Zone Redistribute content from Rasel Web Zone This Agreement
                    shall begin on the date hereof. Parts of this website offer
                    users an opportunity to post and exchange opinions and
                    information in certain areas of the website. Rasel Web Zone
                    does not filter, edit, publish or review Comments prior to
                    their presence on the website. Comments do not reflect the
                    views and opinions of Rasel Web Zone, its agents, and/or
                    affiliates. Comments reflect the views and opinions of the
                    person who post their views and opinions. To the extent
                    permitted by applicable laws, Rasel Web Zone shall not be
                    liable for the Comments or for any liability, damages, or
                    expenses caused and/or suffered as a result of any use of
                    and/or posting of and/or appearance of the Comments on this
                    website. Rasel Web Zone reserves the right to monitor all
                    Comments and to remove any Comments which can be considered
                    inappropriate, offensive, or causes a breach of these Terms
                    and Conditions. You warrant and represent that: You are
                    entitled to post the Comments on our website and have all
                    necessary licenses and consents to do so; The Comments do
                    not invade any intellectual property right, including
                    without limitation copyright, patent, or trademark of any
                    third party; The Comments do not contain any defamatory,
                    libelous, offensive, indecent, or otherwise unlawful
                    material which is an invasion of privacy; The Comments will
                    not be used to solicit or promote business or custom or
                    present commercial activities or unlawful activity. You
                    hereby grant Rasel Web Zone a non-exclusive license to use,
                    reproduce, edit and authorize others to use, reproduce and
                    edit any of your Comments in any and all forms, formats, or
                    media.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="py-10"></div>
      </section>
    </div>
  );
};

export default Terms;
