import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import { Portfolio } from '../pages/Portfolio/Portfolio';
import { AboutArea } from '../pages/About/About';
import { ProjectDetails } from '../pages/Portfolio/PortfolioDetails';
import ContactArea from '../pages/Contact/Contact';
import { Product } from '../pages/Products/Product';
import {ProductDetails} from '../pages/Products/ProductDetails';
import Services from '../pages/Service/Services';
import { ServicesDetails } from '../pages/Service/ServicesDetails';
import { Blog } from '../pages/Blog/Blog';
import { BlogDetails } from '../pages/Blog/BlogDetails';
import Terms from '../pages/Terms/Terms';
import PrivacyPolicy from '../pages/Terms/PrivacyPolicy';


const Routing = () => {
    return (
        <div>
           <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/home' element={<Home/>} />
                <Route path='/about' element={<AboutArea/>} />
                <Route path='/portfolio' element={<Portfolio/>} />
                <Route path='/portfolio/:id' element={<ProjectDetails/> } />
                <Route path='/services' element={<Services/>} />
                <Route path='/services/:id' element={<ServicesDetails/>} />
                <Route path='/products' element={<Product/>} />
                <Route path='/products/:id' element={<ProductDetails/>} />
                <Route path='/terms-conditions' element={<Terms/>} />
                <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                <Route path='/blog/' element={<Blog/>} />
                <Route path='/blog/:id' element={<BlogDetails/>} />
                <Route path='/contact' element={<ContactArea/>} />
           </Routes> 
        </div>
    );
};

export default Routing;