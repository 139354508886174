import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = ({ content }) => {
    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-logo-item">
                                <div className="footer-logo">
                                    <NavLink to="/"> <img src={content.logo} alt="footer-logo-thumb" /></NavLink>
                                </div>
                                <div className="footer-logo-text">
                                    <p>{content.logoText}</p>
                                </div>
                                <div className="footer-logo-icon">
                                    <NavLink to={content.facebookLink}><i className="fa-brands fa-facebook-f"></i></NavLink>
                                    <NavLink to={content.twitterLink}><i className="fa-brands fa-twitter"></i></NavLink>
                                    <NavLink to={content.instagramLink}><i className="fa-brands fa-square-instagram"></i></NavLink>
                                    <NavLink to={content.youtubeLink}><i className="fa-brands fa-youtube"></i></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-link-df">
                                <div className="footer-link">
                                    <div className="footer-link-text text-white">
                                        <h2>{content.linksTitle}</h2>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            {content.links.map((link, index) => (
                                                <li key={index}>
                                                    <NavLink to={link.url}>{link.label}</NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-services-df">
                                <div className="footer-services">
                                    <div className="footer-services-text  text-white">
                                        <h2>{content.servicesTitle}</h2>
                                    </div>
                                    <div className="footer-services-menu">
                                        <ul>
                                            {content.services.map((service, index) => (
                                                <li key={index}>
                                                    <NavLink to={service.url}>{service.label}</NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="official">
                                <div className="official-text  text-white">
                                    <h2>{content.officialTitle}</h2>
                                </div>
                                <div className="official-item">
                                    {content.officialInfo.map((info, index) => (
                                        <div className="official-inner" key={index}>
                                            <div className="icon">
                                                <NavLink to={info.url}><i className={info.icon}></i></NavLink>
                                            </div>
                                            <div className="text">
                                                <NavLink to={info.url}>{info.text}</NavLink>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="official-text-two">
                                    <p>{content.newsletterText}</p>
                                </div>
                                <div className="official-btn">
                                    <div className="official-btn-left">
                                        <input type="text" className="f-b-text" placeholder="Email Address" />
                                    </div>
                                    <div className="official-btn-right">
                                        <NavLink to={content.subscribeLink}>Subscribe</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text">
                                    <h5>{content.copyrightText} <NavLink to="#">{content.madeByText}</NavLink></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
