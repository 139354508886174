import React, { useState } from 'react';
import './Product.css';
import { Camera } from '../../../assets/icons';
import { MdKeyboardArrowLeft,MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { TabContent } from '../../../content/Contents';
import { Button } from '@chakra-ui/react';

export const ProductShowcase = () => {
  // Sample data for demonstration
  const products = [
    { id: 1, name: 'Crypto UI App 1', image: '/img/product/Product-1.png' },
    { id: 2, name: 'Crypto UI App 2', image: '/img/product/Product-2.png' },
    { id: 3, name: 'Crypto UI App 3', image: '/img/product/Product-3.png' },
    { id: 4, name: 'Crypto UI App 4', image: '/img/product/product-4.png' },
    { id: 5, name: 'Crypto UI App 5', image: '/img/product/Product-5.png' },
    { id: 6, name: 'Crypto UI App 6', image: '/img/product/Product-6.png' },
    { id: 7, name: 'Crypto UI App 1', image: '/img/product/Product-1.png' },
    { id: 8, name: 'Crypto UI App 2', image: '/img/product/Product-2.png' },
    { id: 9, name: 'Crypto UI App 3', image: '/img/product/Product-3.png' },
    { id: 10, name: 'Crypto UI App 4', image: '/img/product/product-4.png' },
    { id: 11, name: 'Crypto UI App 5', image: '/img/product/Product-5.png' },
    { id: 12, name: 'Crypto UI App 6', image: '/img/product/Product-6.png' },
    // Add more products as needed
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10; // Change this value to adjust the number of products per page

  // Logic to calculate the index range of products to display based on pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = TabContent.tabItems.slice(indexOfFirstProduct, indexOfLastProduct);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <section className="Product Product-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="Product-head">
                <h2>Our Projects</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="Product-top" >
              <div className="flex flex-wrap justify-start items-start">
                {currentProducts.map(product => (
                  <div className=" w-11/12 mx-auto md:w-4/12 p-4" key={product.id}>
                    <div className="Product-item mt-pt">
                      <div className="Product-img" style={{height:'350px'}}>
                        <img src={product.imgSrc} alt="Product-thumb" className='h-full object-cover' />
                        <div className="product-overlay flex items-center justify-center">

                          <NavLink to={`/portfolio/${product.id}`} >View Project</NavLink>
                        </div>
                      </div>
                      <div className="Product-text  py-4 px-3 rounded-xl" style={{left:'10px', bottom:'10px', background:"rgb(255 255 255 /78%)"}}>
                        <p style={{color:"black"}} className='py-2'>{product.categoryTitle}</p>
                        <h6 className='pl-5 text-sm text-slate-900' style={{color:"black"}}>{product.title}</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="h-1-blog-btn">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button className="page-link px-2" onClick={() => paginate(currentPage - 1)}>
                        <MdKeyboardArrowLeft />

                      </button>
                    </li>
                    {Array.from({ length: Math.ceil(products.length / productsPerPage) }, (_, i) => (
                      <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => paginate(i + 1)}>
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === Math.ceil(products.length / productsPerPage) ? 'disabled' : ''}`}>
                      <button className="page-link flex items-center justify-center" onClick={() => paginate(currentPage + 1)}>
                        <MdKeyboardArrowRight/>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
