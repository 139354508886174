import React from 'react'
import { About } from '../Home/components/About'
import { Skills } from '../Home/components/Skills'
import HeroArea from '../Home/components/Herro'
import Services from '../Home/components/Service'

export const AboutArea = () => {
  return (
    <div>
        <HeroArea/>
        <About/>
        <Services />
        <Skills
        title="Hire me as a Product Designer"
        description="I am a product designer excited about unique ideas and helping clients achieve their goals."
        buttonText="Download CV"
        buttonLink="#"
      />
    </div>
  )
}
