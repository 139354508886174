import React, { useState } from 'react';
import Slider from 'react-slick';
import { NavLink } from 'react-router-dom';

export const Blog = () => {
  // Sample blog data (replace it with your actual data)
  const [blogs, setBlogs] = useState([
    {
      id: 1,
      title: 'Creativo para jóvenes: a Designer’s UI/UX complete checklist.',
      image: '/img/blog/blog-1.png',
      category1: 'Digital',
      category2: 'Marketing',
      readDuration: '3 days read',
      link: 'home-1blog-detalis.html',
    },
    {
      id: 1,
      title: 'Creativo para jóvenes: a Designer’s UI/UX complete checklist.',
      image: '/img/blog/blog-1.png',
      category1: 'Digital',
      category2: 'Marketing',
      readDuration: '3 days read',
      link: 'home-1blog-detalis.html',
    },
    {
      id: 1,
      title: 'Creativo para jóvenes: a Designer’s UI/UX complete checklist.',
      image: '/img/blog/blog-1.png',
      category1: 'Digital',
      category2: 'Marketing',
      readDuration: '3 days read',
      link: 'home-1blog-detalis.html',
    },
    // Add more blog objects as needed
  ]);

   const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };;

  return (
    <section className="blog ">
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-head py-3">
              <h2>Stay up to Date with our News.</h2>
            </div>
          </div>
        </div>
        <Slider {...settings} >
          {blogs.map((blog,index) => (
             <div className='w-full py-5' key={index}>
                <div key={blog.id} className="blog-p w-full">
                    <div className="blog-item">
                      <div className="blog-img">
                        <img src={blog.image} alt="blog-thumb" />
                      </div>
                      <div className="blog-inner">
                        <div className="blog-inner-btn">
                          <NavLink to="#" tabIndex={-1}>
                            {blog.category1}
                          </NavLink>
                          <NavLink to="#" tabIndex={-1}>
                            {blog.category2}
                          </NavLink>
                        </div>
                        <div className="blog-inner-text">
                          <NavLink to={blog.link}>{blog.title}</NavLink>
                        </div>
                        <div className="blog-btn">
                          <NavLink to="#" tabIndex={-1}>
                            {blog.readDuration}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                </div>
             </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
