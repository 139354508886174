import React from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Github, LinkedIn, Medium, StackOverFollow } from '../../../assets/icons';

const Portfolio = () => {
    const portfolioItems = [
        {
            url: "https://stackoverflow.com/users/12962710/rasel-hossain",
            name: "S. Overflow",
            icon: <StackOverFollow />,
        },
        {
            url: "https://github.com/raselhossain08 ",
            name: "Github",
            icon: <Github />,
        },
        {
            url: "https://medium.com/@rasel.hossain08",
            name: "Medium",
            icon: <Medium />,
        },
        {
            url: "https://www.linkedin.com/in/rasel-hossen-32b51915b/",
            name: "Linkedin",
            icon: <LinkedIn />,
        },
    ];

    // Settings for the slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nav:false,
        arrows:false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className=''>
            <section className="portfolio">
                <div className="container py-10">
                    <Slider {...settings}>
                        {portfolioItems.map((item, id) => (
                            <div key={id} className='px-4'>
                            <div className="portfolio-item" >
                                <NavLink to={item.url}>
                                    <ul>
                                        <li className='flex items-center justify-center'>
                                            <span className='mx-2 block'>{item.icon}</span>
                                            {item.name}
                                        </li>
                                    </ul>
                                </NavLink>
                            </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    );
};

export default Portfolio;
