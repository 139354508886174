import React from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
export const ProductDetails = () => {
    const productContent={
        imageSrc:"./assets/image/others/p-d-img.png",
        title:"Master in Design System in Figam & Sketch",
        price:"$1700.0",
        discountPrice:"$2900.99",
        rating:4,
        numReviews:310,
        details:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        additionalDetails:[
            { "Design Age": "2 Months", "Editable": "yes", "Free Update": "yes"},
            { "Resale": "no", "Update": "yes", "Cancel": "yes" },
            { "Gas": "yes", "Reservations": "yes", "Accepts Credit Card": "yes" }
        ]
    
    }
    const slides=[
        { imageSrc: "/assets/img/additional-details.png", alt: "thumb" },
        { imageSrc: "/assets/img/additional-details-1.png", alt: "thumb" },
        // Add more slides as needed
    ]
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <section className="product-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="product-details-img">
                                <img src={productContent.imageSrc} alt="thumb" />
                            </div>
                        </div>
                    </div>
                    <div className="row product-details-mt ">
                        <div className="col-lg-7">
                            <div className="product-details-text">
                                <h2>{productContent.title}</h2>
                            </div>
                        </div>
                        <div className="col-lg-5 text-end">
                            <div className="product-details-text-right">
                                <p className='flex items-center'>
                                    {productContent.discountPrice && <><del>{productContent.discountPrice}</del>{" "}</>}
                                    {productContent.price}
                                    <span>
                                        <img src="/assets/img/icon1.svg" alt="icon1" style={{ width: "30px" }} />
                                    </span>{" "}
                                </p>
                            </div>
                            <div className="product-details-text-right-icon text">
                                <div className="icon">
                                    {Array.from({ length: productContent.rating }, (_, index) => (
                                        <i key={index} className="fa-solid fa-star" />
                                    ))}
                                    {productContent.numReviews && <span>({productContent.numReviews})</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="product-details-middel">
                                <h5>Product Details</h5>
                                <p>{productContent.details}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="additional-details">
                                <h4>Additional Details</h4>
                            </div>
                            <div className="additional-details-item">
                                {productContent.additionalDetails.map((item, index) => (
                                    <div key={index} className="additional-details-inner">
                                        {Object.entries(item).map(([key, value]) => (
                                            <p key={key}>
                                                {key}: <span>{value}</span>{" "}
                                            </p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className="additional-details-btn">
                                <div className="btn-style-1">
                                    <NavLink to="#">Purchase now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row additional-details-top">
                        <Slider {...settings}>
                            {slides.map((slide, index) => (
                                <div key={index} className="additional-pr">
                                    <div className="additional-details-slick">
                                        <img src={slide.imageSrc} alt={slide.alt} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

            </section>
        </div>
    );
}


