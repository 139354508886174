import React from "react";
import HeroArea from "../Home/components/Herro";

const PrivacyPolicy = () => {
  return (
    <div>
      <HeroArea />
      <section className="services-details">
        <div className="container">
          <div className="row article">
            <div className="col-lg-12">
              <div className="blog-details-article-head">
                <h1 className=" text-2xl font-bold">Privacy Policy</h1>
                <p className="py-3">Welcome to Rasel Web Zone!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="website">
                <div className="website-text">
                  <span>
                  If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at rahatulwebzone@gmail.com .
                  </span>
                  <h2 className=" text-2xl font-bold pt-5">Log Files</h2>
                  <p>
                  Rasel Web Zone follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services' analytics. The information collected by log files includes internet protocol (IP)
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="py-10"></div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;


