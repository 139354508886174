import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const Contact = () => {
  const [accordionItems, setAccordionItems] = useState([
    {
      id: 1,
      title: 'Innovation and Technology',
      content: 'Explore the latest trends and innovations shaping the tech landscape.Learn how emerging technologies are revolutionizing industries worldwide.',
      isOpen: false
    },
    {
      id: 2,
      title: 'Creativity and Design',
      content: 'Uncover tips and tricks for fostering creativity in design.Discover how great design principles can enhance user experiences.',
      isOpen: false
    },
    {
      id: 3,
      title: 'Productivity and Growth',
      content: 'Dive into strategies for boosting productivity and achieving personal growth.Find inspiration to maximize efficiency and reach your full potential.',
      isOpen: false
    }
  ]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    description: ''
  });

  const handleAccordionClick = (id) => {
    setAccordionItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestData = {
      accordionItems,
      formData
    };
    console.log('Request Data:', requestData);
    // You can now send this requestData object to your backend
  };

  return (
    <section className="thought">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 p-r">
            <div className="thought-text py-4">
              <h2>Have any Thought Find here.</h2>
            </div>
            <div className="faq">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionItems.map(item => (
                  <div className="accordion-item" key={item.id}>
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${item.isOpen ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => handleAccordionClick(item.id)}
                      >
                        <span>{item.id}</span>
                        {item.title}
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse collapse ${item.isOpen ? 'show' : ''}`}
                      id={`flush-collapse${item.id}`}
                    >
                      <div className="accordion-body text-black">
                        <p>{item.content}</p>
                        <NavLink href="#">
                          Learn more about this{" "}
                          <i className="fa-solid fa-arrow-right" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="send">
              <div className="send-item">
                <form onSubmit={handleSubmit}>
                  <div className="send-inner">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">Last Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="send-inner">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">E-mail*</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="telephone" className="form-label">Telephone*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="telephone"
                        name="telephone"
                        value={formData.telephone}
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description*</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder=""
                      rows="4"
                      required
                    />
                  </div>
                  <div className="send-btn">
                    <div className="btn-style-1">
                      <button type="submit">Send now!</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
