import { Desktop, Icons } from "../assets/icons";
// header content
export const HeaderContent = {
    MenuData: [
        {
            name: "Home",
            url: "home",
        },
        {
            name: "About Me",
            url: "about",
        },
        {
            name: "Portfolio",
            url: "portfolio",
        },
        {
            name: "Services",
            url: "services",
        },
        // {
        //     name: "Shop ",
        //     url: "shop",
        //     subMenu: [
        //         {
        //             name: "Products",
        //             url: "products",
        //         },
        //     ],
        // },
        {
            name: "Blog",
            url: "blog",
        },
        {
            name: "contact",
            url: "contact",
        },
    ],
    SocialAccounts: [
        {
            name: "Facebook",
            url: "https://www.facebook.com/yourpage",
            icon: "fa-brands fa-facebook-f", // Path to the icon image file
        },
        {
            name: "Twitter",
            url: "https://twitter.com/yourhandle",
            icon: "fa-brands fa-twitter", // Path to the icon image file
        },
        {
            name: "Instagram",
            url: "https://www.instagram.com/youraccount",
            icon: "fa-brands fa-instagram", // Path to the icon image file
        },
        {
            name: "Linkedin",
            url: "https://www.instagram.com/youraccount",
            icon: "fa-brands fa-linkedin-in", // Path to the icon image file
        },
        // Add more social media accounts as needed
    ]
}
// mobile Header content
export const MobileHeaderContent = {
    logo: {
        image: "/img/black.png",
        link: "/"
    },
    navLinks: [
        {
            title: "Home",
            url: "home",
        },
        {
            title: "Portfolio",
            url: "portfolio",
        },
        {
            title: "Service",
            url: "service",
        },
        {
            title: "Blog",
            url: "blog",
        },
        // {
        //     title: "Shop",
        //     url: "Shop",
        //     subLinks: [
        //         { title: "Product", url: "product" },
        //     ]
        // },
        {
            title: "About Me",
            url: "about.html"
        },
        {
            title: "Contact",
            url: "contact"
        }
    ],
    socialIcons: [
        { url: "#", class: "fa-brands fa-facebook-f" },
        { url: "#", class: "fa-brands fa-twitter" },
        { url: "#", class: "fa-brands fa-youtube" },
        { url: "#", class: "fa-brands fa-linkedin-in" }
    ]
};

// AboutArea content
export const AboutAreaContent = {
    resumeHead: {
        name: "Rasel Hossain",
        title: "Web developer",
        yearsOfExperience: 4,
        experienceText: "Hello there! I Rasel Hossain .I am a full stack developer  . I'm passionate about something. Throughout the five years that I've worked in the web development industry, I've gathered experience.",
    },
    resumeImage: "/img/about.png",
    experiences: [
        {
            title: "Full Stack Developer",
            imageUrl: "/img/logo/Fiverr.png",
            date: "Current",
            heightOf: "100px",
        },
        {
            title: "Web Developer",
            imageUrl: "/img/logo/younivison.png",
            date: "2018-2020",
            heightOf: "100px",
        },
        {
            title: "Full Stack Developer",
            imageUrl: "/img/logo/upwork.png",
            date: "2018-2020",
        },
    ],
    skills: [
        { name: "Html", link: "#" },
        { name: "CSS", link: "#", active: true },
        { name: "Java Script", link: "#" },
        { name: "Angular Js", link: "#" },
        { name: "Vue Js", link: "#" },
        { name: "Next Js", link: "#" },
        { name: "React Js", link: "#" },
        { name: "React Native", link: "#" },
        { name: "Ionic", link: "#" },
        { name: "PHP", link: "#" },
        { name: "Python", link: "#" },
        { name: "Node Js", link: "#" },
        { name: "Websocket", link: "#" },
        { name: "Express Js", link: "#" },
        { name: "Mongodb", link: "#" },
        { name: "MYSQL", link: "#" },
        { name: "Wordpress", link: "#" },
        { name: "Figma", link: "#" },
        { name: "Abode Photoshop", link: "#" },
        { name: "Front-end", link: "#" },
        { name: "Back-end", link: "#" },
    ],
    ToolItems: [
        { src: "/img/icon/vs.png" },
        { src: "/img/icon/ps.png" },
        { src: "/img/icon/figma.png" },
        { src: "/img/icon/css.png" },
        { src: "/img/icon/html.png" },
        { src: "/img/icon/atom.png" },
        { src: "/img/icon/react-native.png" },
        { src: "/img/icon/chakra.svg" },
        { src: "/img/icon/antd.svg" },
        { src: "/img/icon/sql.png" },
        { src: "/img/icon/db.png" },
        { src: "/img/icon/ExpressJs.png" },
        { src: "/img/icon/js.png" },
        { src: "/img/icon/primeng.png" },
        { src: "/img/icon/bootstrap.png" },
        { src: "/img/icon/tailwind.png" },
        { src: "/img/icon/material.png" },
        { src: "/img/icon/node.png" },
        { src: "/img/icon/ionic.png" },
        { src: "/img/icon/angular.png" },
        { src: "/img/icon/nextjs.png" },
        { src: "/img/icon/python.png" },
        { src: "/img/icon/php.png" },
        { src: "/img/icon/wordpress.png" },
    ],
};
// Service content
export const ServiceContent = {
    SlideSettings: {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: "0",
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    },
    ServiceData: {
        title: "Professional Assistance! Let's investigate it.",
        details: "We take your thoughts and desires and turn them into a one-of-a-kind web project that will motivate both you and your clients. We have a team of experts to handle your issues."
    },
    slideContent: [
        {
            title: "Mobile App Development",
            details: "We specialize in creating cutting-edge mobile applications for iOS and Android platforms.",
            icon: Icons.mobile,
            url: ""
        },
        {
            title: "Web App Development",
            details: "From complex web applications to simple prototypes, we craft solutions that drive business growth",
            icon: Icons.web,
            url: ""
        },
        {
            title: "WordPress Website Development",
            details: "Transform your ideas into captivating WordPress websites with our expert development services",
            icon: Icons.wordpress,
            url: ""
        },
    ]
}
// Project Content 
export const ProjectContent = {
    title: "The Achievement my Project.",
    subtitle: "There are many variations of passages of Ipsum available, majority have suffered.",
    features: [
        {
            feature: "Suspe ndisse suscipit sagittis leo."
        },
        {
            feature: "Suspe ndisse suscipit sagittis leo."
        },
        {
            feature: "Entum estibulum dignissim as posuere."
        }
    ],
    statistics: [
        {
            icon: "",
            value: "150k",
            label: "Co-agent"
        },
        {
            icon: "",
            value: "120k",
            label: "Branches around"
        },
        {
            icon: "",
            value: "100k",
            label: "Built Hose"
        },
        {
            icon: "",
            value: "100k",
            label: "Built Hose"
        }
    ]
}


// Tab content
export const TabContent = {
    seeMoreUrl: "/portfolio",
    tabHeadTitle: "Let’s Check my Best work",
    tabHeadDetails: "Explore a curated selection of our finest projects, spanning mobile apps, web applications, and WordPress websites. Discover how our expertise and innovation have brought our clients' visions to life in stunning digital experiences.",
    tabItems: [
        {
            id: 1,
            imgSrc: "/img/MyProjects/quixient.png",
            title: "Corporate React Web Application",
            author: "Admin",
            date: "31 May 2022",
            mainText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library of 2,000 books on
          your smartphone.`,
            secondaryText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library.`,
            bottomText: `We can easily manage if we will only take, each day, the burden it.
          But the load will be too heavy for us if we carry yesterday’s burden over again today.
          This book is a treatise on the theory of ethics, very popular during the Renaissance.
          The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.`,
            finalText: `With so many alternatives, choosing can be difficult. Don’t know where to start?
          ABA English can guide you. Our texts are organized by level so that you can learn with the right materials.`,
            livePreview: "https://quixient.vercel.app/",
            category: "Web Applications",
            categoryTitle: "Web Applications",
            group: ["Web Applications"],

        },
        {
            id: 2,
            imgSrc: "/img/MyProjects/Principles.png",
            author: "Admin",
            date: "31 May 2022",
            mainText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library of 2,000 books on
          your smartphone.`,
            secondaryText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library.`,
            bottomText: `We can easily manage if we will only take, each day, the burden it.
          But the load will be too heavy for us if we carry yesterday’s burden over again today.
          This book is a treatise on the theory of ethics, very popular during the Renaissance.
          The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.`,
            finalText: `With so many alternatives, choosing can be difficult. Don’t know where to start?
          ABA English can guide you. Our texts are organized by level so that you can learn with the right materials.`,
            title: "Principles Academy React Web Applications",
            livePreview: "",
            categoryTitle: "Web Applications",
            group: ["Web Applications"],
        },
        {
            id: 3,
            imgSrc: "/img/MyProjects/longx.png",
            title: "Longx is a Money Exchanging Angular Web Application",
            author: "Admin",
            date: "31 May 2022",
            mainText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library of 2,000 books on
          your smartphone.`,
            secondaryText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library.`,
            bottomText: `We can easily manage if we will only take, each day, the burden it.
          But the load will be too heavy for us if we carry yesterday’s burden over again today.
          This book is a treatise on the theory of ethics, very popular during the Renaissance.
          The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.`,
            finalText: `With so many alternatives, choosing can be difficult. Don’t know where to start?
          ABA English can guide you. Our texts are organized by level so that you can learn with the right materials.`,
            livePreview: "",
            categoryTitle: "Web Applications",
            group: ["Web Applications"],
        },
        {
            id: 4,
            imgSrc: "/img/MyProjects/one.png",
            title: "One is a Dashboard of React js Web Application",
            author: "Admin",
            date: "31 May 2022",
            mainText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library of 2,000 books on
          your smartphone.`,
            secondaryText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library.`,
            bottomText: `We can easily manage if we will only take, each day, the burden it.
          But the load will be too heavy for us if we carry yesterday’s burden over again today.
          This book is a treatise on the theory of ethics, very popular during the Renaissance.
          The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.`,
            finalText: `With so many alternatives, choosing can be difficult. Don’t know where to start?
          ABA English can guide you. Our texts are organized by level so that you can learn with the right materials.`,
            livePreview: "https://oneadmin.vercel.app/",
            categoryTitle: "Web Applications",
            group: ["Web Applications"],
        },
        {
            id: 5,
            imgSrc: "/img/MyProjects/moneyFox.png",
            title: "MoneyFox Is a Angular Dashboard",
            author: "Admin",
            date: "31 May 2022",
            mainText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library of 2,000 books on
          your smartphone.`,
            secondaryText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library.`,
            bottomText: `We can easily manage if we will only take, each day, the burden it.
          But the load will be too heavy for us if we carry yesterday’s burden over again today.
          This book is a treatise on the theory of ethics, very popular during the Renaissance.
          The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.`,
            finalText: `With so many alternatives, choosing can be difficult. Don’t know where to start?
          ABA English can guide you. Our texts are organized by level so that you can learn with the right materials.`,
            livePreview: "",
            categoryTitle: "Web Applications",
            group: ["Web Applications"],
        },
        {
            id: 6,
            imgSrc: "/img/MyProjects/jifu.png",
            title: "Jifu is a React Web Application",
            author: "Admin",
            date: "31 May 2022",
            mainText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library of 2,000 books on
          your smartphone.`,
            secondaryText: `The internet has changed our ways of accessing knowledge as
          well as information and art. Before, you would have had to go
          to a library, bookstore, magazine stand, or something similar.
          You wouldn’t find as many titles as you find online and you’d
          normally have to pay for them or borrow them for a few days.
          Nowadays, you’re lucky. You just need Google to find thousands
          of ways to read about your favorite subjects. You can choose
          as many as you want and even keep a library.`,
            bottomText: `We can easily manage if we will only take, each day, the burden it.
          But the load will be too heavy for us if we carry yesterday’s burden over again today.
          This book is a treatise on the theory of ethics, very popular during the Renaissance.
          The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.`,
            finalText: `With so many alternatives, choosing can be difficult. Don’t know where to start?
          ABA English can guide you. Our texts are organized by level so that you can learn with the right materials.`,
            livePreview: "",
            categoryTitle: "Web Applications",
            group: ["Web Applications"],
        },

        // Add other tab items
    ]

}

// footer Content
export const footerContent = {
    logo: "/img/white.png",
    logoText: "We are passionate about taking on new challenges in the advertising industry.",
    facebookLink: "https://www.facebook.com/rahatul.islam08/",
    twitterLink: "https://twitter.com/rahatul_islam08",
    instagramLink: "https://www.instagram.com/rahatul_islam_08/",
    youtubeLink: "https://www.youtube.com/@rahatulwebzone4332",
    linksTitle: "Links",
    links: [
        { label: "Home", url: "/" },
        { label: "Service", url: "/service" },
        { label: "Blog", url: "/blog" },
        { label: "Contact US", url: "/contact" }
    ],
    servicesTitle: "Services",
    services: [
        { label: "Contact & Faq", url: "#" },
        { label: "Terms and Conditions", url: "/terms-conditions" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Trade Program", url: "#" }
    ],
    officialTitle: "Official info:",
    officialInfo: [
        { icon: "fa-solid fa-location-dot", text: "Sultanpur , Satkhira, Bangladesh", url: "#" },
        { icon: "fa-solid fa-phone", text: "8801757220402", url: "tel:8801757220402" }
    ],
    newsletterText: "Rasel Web Zone",
    subscribeLink: "#",
    copyrightText: "Copyright © 2024 – All Rights Reserved.",
    madeByText: "Rasel Hossain"
};
