import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const HeroArea = ({title}) => {
  const location = useLocation();
  const [currentNav, setCurrentNav] = useState('');

  useEffect(() => {
    // Function to extract current navigation from the URL
    const getCurrentNavFromURL = () => {
      const pathArray = location.pathname.split('/');
      // Get the last segment of the URL (current navigation)
      const nav = pathArray[pathArray.length - 1];
      return nav;
    };

    // Update current navigation when the URL changes
    setCurrentNav(getCurrentNavFromURL());
  }, [location]);

  return (
    <div>
      <section className="inner-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-bg-head capitalize">
                {/* Render current navigation dynamically */}
                <h1>{ title? title: currentNav}</h1>
              </div>
              <div className="inner-item">
                <div className="inner-text capitalize">
                  <NavLink to="/">home</NavLink>
                </div>
                <div className="icon">
                  <span>
                    <i className="fa-sharp fa-solid fa-angle-right" />
                  </span>
                </div>
                <div className="inner-text capitalize">
                  {/* Render current navigation dynamically */}
                  <h5>{ title? title: currentNav} </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroArea;
