import React from 'react'
import './Blog.css'
export const Comment = () => {
  return (
    <div><section className="comment">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="comment-head">
            <h4>03 Comments</h4>
          </div>
          <div className="comment-item">
            <div className="comment-inner">
              <div className="comment-img">
                <img src="./assets/image/others/comment-1.png" alt="thumb" />
              </div>
              <div className="comment-text">
                <div className="text">
                  <h4>Michel Holder</h4>
                  <span>June 1, 2022 at 11:27 AM</span>
                </div>
                <p>
                  Sure there isn't anything embarrassing hidden in the middles of
                  text. All into a Some this erato tend to repeat predefined
                  chunks.
                </p>
                <a href="#">Reply</a>
              </div>
            </div>
            <div className="comment-inner comment-inner-two">
              <div className="comment-img">
                <img src="./assets/image/others/commrnt-2.png" alt="thumb" />
              </div>
              <div className="comment-text">
                <div className="text">
                  <h4>Kathryn Murphy</h4>
                  <span>June 1, 2022 at 11:27 AM</span>
                </div>
                <p>
                  Sure there isn't anything embarrassing in the middles of text.
                  All into a this erato Internet tend
                </p>
                <a href="#">Reply</a>
              </div>
            </div>
            <div className="comment-inner ">
              <div className="comment-img">
                <img src="./assets/image/others/commrnt-3.png" alt="thumb" />
              </div>
              <div className="comment-text">
                <div className="text">
                  <h4>Albert Flores</h4>
                  <span>June 1, 2022 at 11:27 AM</span>
                </div>
                <p>
                  Sure there isn't anything embarrassing in the middles of text.
                  All into a this erato Internet tend
                </p>
                <a href="#">Reply</a>
              </div>
            </div>
            <div className="comment-inner ">
              <div className="comment-img">
                <img src="./assets/image/others/comment-4.png" alt="thumb" />
              </div>
              <div className="comment-text">
                <div className="text">
                  <h4>Leslie Alexander</h4>
                  <span>June 1, 2022 at 11:27 AM</span>
                </div>
                <p>
                  Sure there isn't anything embarrassing in the middles of text.
                  All into a this erato Internet tend
                </p>
                <a href="#">Reply</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 b-pl">
          <div className="sarch-box">
            <div className="sarch-box-head">
              <h2>Search</h2>
            </div>
            <div className="search-main">
              <input
                type="text"
                className="search-input"
                placeholder="search..."
              />
              <button className="search-button">
                <i className="fas fa-search" />
              </button>
            </div>
          </div>
          <div className="latest-box">
            <div className="latest-box-head">
              <h2>Latest Blog</h2>
            </div>
            <div className="latest-box-item">
              <div className="latest-box-inner">
                <div className="latest-box-img">
                  <a href="#">
                    {" "}
                    <img
                      src="./assets/image/others/latest-box-1.png"
                      alt="thumb"
                    />
                  </a>
                </div>
                <div className="latest-box-text">
                  <a href="#">The Start-Up Ultimate Guide to Make ....</a>
                  <p>
                    <span>
                      <img src="./assets/image/others/icon/Icon.png" alt="icon" />
                    </span>
                    31 May 2023
                  </p>
                </div>
              </div>
              <div className="latest-box-inner">
                <div className="latest-box-img">
                  <a href="#">
                    {" "}
                    <img
                      src="./assets/image/others/latest-box-3.png"
                      alt="thumb"
                    />
                  </a>
                </div>
                <div className="latest-box-text">
                  <a href="#">Definitive Guide to Make a Daily ....</a>
                  <p>
                    <span>
                      <img src="./assets/image/others/icon/Icon.png" alt="icon" />
                    </span>
                    31 May 2023
                  </p>
                </div>
              </div>
              <div className="latest-box-inner">
                <div className="latest-box-img">
                  <a href="#">
                    {" "}
                    <img
                      src="./assets/image/others/latest-box-1.png"
                      alt="thumb"
                    />
                  </a>
                </div>
                <div className="latest-box-text">
                  <a href="#">The Start-Up Ultimate Guide to Make ....</a>
                  <p>
                    <span>
                      <img src="./assets/image/others/icon/Icon.png" alt="icon" />
                    </span>
                    31 May 2023
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tag">
            <div className="tag-head">
              <h2>Tag</h2>
            </div>
            <div className="tag-btn">
              <a href="#">Saas Landing</a>
              <a href="#">UI kit</a>
              <a href="#">App</a>
              <a href="#">Web UI</a>
              <a className="tag-active" href="#">
                iOS
              </a>
              <a href="#">Landing page</a>
              <a href="#">Mobile UI</a>
              <a href="#">Wirefram</a>
              <a href="#">UIUX</a>
              <a href="#">UI Color</a>
              <a href="#">Prototyping</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}
