import React, { useEffect, useState } from "react";
import './PortfolioDetails.css'
import HeroArea from "../../Home/components/Herro";
import { NavLink, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Facebook, Linkedin, Twitter, Youtube } from "../../../assets/icons";
import { TabContent } from "../../../content/Contents";
import { Button } from "@chakra-ui/react";
export const ProjectDetails = () => {
  const { id } = useParams();
  const [portfolioContent, setProject] = useState(null);
  const [similarProjects, setSimilarProjects] = useState([]);
  // Fetching project data based on id
  useEffect(() => {
    // Assuming TabContent.tabItems contains project data
    const data = TabContent.tabItems;
    const foundProject = data.find(item => item.id === parseInt(id));
    setProject(foundProject);

    // Filter out the current project from similar projects
    const filteredProjects = data.filter(item => item.id !== parseInt(id));
    setSimilarProjects(filteredProjects);
  }, [id]);

  if (!portfolioContent) {
    return <div>Loading...</div>;
  }

  console.log(portfolioContent)
  const shareImages = [
    {
      imageUrl: "/img/share-1.png"
    },
    {
      imageUrl: "/img/share-2.png"
    },
    {
      imageUrl: "/img/share-3.png"
    }
  ];
  const shareLinks = [
    {
      url: '',
      icon: Twitter,
      alt: 'Twitter',
    },
    {
      url: '',
      icon: Facebook,
      alt: 'Facebook',
    },
    {
      url: '',
      icon: Linkedin,
      alt: 'Linkedin',
    },
    {
      url: '',
      icon: Youtube,
      alt: 'Youtube',
    },
    // Add more social media share links as needed
  ];


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <div>
      <HeroArea title="Portfolio" />
      <section className="portfolio-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details-head flex justify-center">
                <h2 className="py-10">{portfolioContent.title}</h2>
              </div>
              <div className="portfolio-details-img flex justify-center">
                <img src={portfolioContent.imgSrc} alt="thumb" />
              </div>
              <div className="portfolio-details-text ">
              </div>
              <div className="portfolio-details-text py-3">
                <Button type="button" className="btn-style-1" colorScheme="#2662fa"> Live Preview</Button>
              </div>
              <div className="portfolio-details-text">
                <div className="portfolio-details-text-item">
                  <div className="icon">
                    <img src="/img/user.png" alt="thumb" />
                  </div>
                  <div className="text">
                    <p>By {portfolioContent.author}</p>
                  </div>
                </div>
                <div className="portfolio-details-text-item">
                  <div className="icon">
                    <img src="/img/Icon.png" alt="thumb" />
                  </div>
                  <div className="text">
                    <p>{portfolioContent.date}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="portfolio-details-middle-text">
                <p>{portfolioContent.mainText}</p>
                <span>{portfolioContent.secondaryText}</span>
              </div>
              <div className="portfolio-details-middle-img">
                <p>{portfolioContent.bottomText}</p>
              </div>
              <div className="portfolio-details-btm">
                <p>{portfolioContent.finalText}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {shareImages.map((image, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <div className="share-img">
                  <img src={image.imageUrl} alt="thumb" />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="share-btn">
                <div className="text">
                  <h3>Share:</h3>
                </div>
                <div className="share-icon">
                  {shareLinks.map((link, index) => (
                    <div className="icon" key={index}>
                      <span>
                        <NavLink to={link.url} target="_blank">
                            {link.icon}
                        </NavLink>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="similar-text">
                <h3>Similar</h3>
              </div>
            </div>
          </div>
          <Slider {...settings} className="bg-white">
            {similarProjects.map(slide => (
              <div className="p-3 bg-white" key={slide.id}>
                <div className="Product-item rounded-lg overflow-hidden">
                  <div className="Product-img">
                    <img src={slide.imgSrc} alt={slide.alt} className="rounded-lg"/>
                    <div className="product-overlay flex items-center justify-center">
                      <NavLink to={`/portfolio/${slide.id}`}>View Project</NavLink>
                    </div>
                  </div>
                  <div className="Product-text p-4">
                    <p>{slide.title}</p>
                    <h6>{slide.appDescription}</h6>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};
